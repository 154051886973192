const normalizeBreadcrumb = (crumb) => {
  return {
    id: null,
    name: null,
    label: null,
    refinementKey: null,
    dimensionName: 'Category',
    ...crumb // what is needed from the breadcrumb??? :sob:
  };
};

export const processBreadcrumbs = (breadcrumbs = []) => {
  const categories = [];
  const refinements = [];
  if (!breadcrumbs || !Array.isArray(breadcrumbs) || breadcrumbs.length === 0) {
    return [categories, refinements];
  }
  breadcrumbs.forEach((crumb) => {
    if (crumb.dimensionName === 'Category') {
      categories.push(crumb.label);
    } else if (crumb.dimensionName === 'Review Rating' && crumb.label === '5') {
      refinements.push('Best Rated');
    } else if (crumb.label !== 'Home') {
      refinements.push(crumb.label);
    }
  });

  return [categories, refinements];
};

export const getDescription = (pageType, categories = [], refinements = [], searchVal, isContentful) => {
  if (pageType !== 'b') return `Search Results for ${searchVal} at The Home Depot`;
  let refinedCategories = '';
  let department = '';
  if (categories.length > 0) {
    // THIS CODE WAS IN CANVAS BUT IT CONTRADICTS REQUIREMENTS FROM SEO
    // REMOVING IT BUT WE MIGHT NEED TO REVISIT IT.
    // condition added to check for browse/search page
    // if (categories.length === 4) {
    //   department = categories[categories.length - 2];
    // } else {
    //   [department] = categories;
    // }
    [department] = categories;
    if (categories.length === 1) {
      if (refinements.length === 1) {
        refinedCategories = refinements[0];
      } else if (refinements.length > 1) {
        refinedCategories = refinements.filter(Boolean).join(', ');
      } else {
        refinedCategories = categories[0];
      }
    } else if (categories.length > 1) {
      let highlyRatedRatingIndex = refinements.indexOf('4 & Up');

      if (highlyRatedRatingIndex !== -1) {
        let processedRefinements = refinements;
        processedRefinements[highlyRatedRatingIndex] = 'Highly Rated';
        const reverseRefinements = processedRefinements.slice().reverse();
        refinedCategories = `${reverseRefinements.join(', ')} ${categories[categories.length - 1]}`.trim();
      } else {
        const reverseRefinements = refinements.slice().reverse();
        refinedCategories = `${reverseRefinements.join(', ')} ${categories[categories.length - 1]}`.trim();
      }
    }
  } else {
    refinedCategories = refinements.join(', ');
  }
  let description;
  const furnitureIndex = categories.indexOf('Furniture');
  const pickUpTodayIndex = refinements.indexOf('Pick Up Today');
  if (pickUpTodayIndex !== -1 && furnitureIndex === -1 && categories.length > 0) {
    let refinementsWithoutPickUPToday = refinements.filter((refinement) => refinement !== 'Pick Up Today');
    // eslint-disable-next-line max-len
    description = `Find ${refinementsWithoutPickUPToday.reverse().join(', ')} ${categories[categories.length - 1]} ready to be picked up today at your local Home Depot store.`;
    return description;
  }
  // Major Appliances no longer offers free shipping, so we need to update the description for these pages
  if (categories[0] === 'Appliances' && pageType === 'b') {
    const majorAppliancesDescription = `Browse our online aisle of ${refinedCategories}. `
      + `Shop The Home Depot for all your ${department} and DIY needs.`;
    return majorAppliancesDescription;
  }
  description = `Get free shipping on qualified ${refinedCategories} products or Buy Online Pick Up in Store today`;
  if (department) {
    description += ` in the ${department} Department`;
  }
  if (isContentful) {
    // eslint-disable-next-line max-len
    description = `Shop ${refinedCategories} and more at The Home Depot. We offer free delivery, in-store and curbside pick-up for most items`;
  }
  description = `${description}.`;
  return description;
};

export const getTitleTag = (
  pageType,
  categories = [],
  refinements = [],
  searchVal,
  pathWithQueryParams,
  isContentful
) => {
  let breadCrumbLabel = 'Browse';
  const isBrowseInSearch = pathWithQueryParams?.indexOf('Ntt-') > -1;
  switch (pageType) {
  case 'b':
    // multiple pages indicates breadcrumbs that should be joined by ' - '
    if (isBrowseInSearch) {
      breadCrumbLabel = `Search Results for ${searchVal} at`;
    } else if (categories?.length >= 0) {
      // only show up to two Category breadcrumbs for category pages and one Category breadcrumbs for contentful
      const titleCategories = isContentful
        ? categories.slice().reverse().slice(0, 1)
        : categories.slice().reverse().slice(0, 2);
      const furnitureIndex = categories.indexOf('Furniture');
      const pickUpTodayIndex = refinements.indexOf('Pick Up Today');
      let bestRatedRatingIndex = refinements.indexOf('Best Rated');
      let highlyRatedRatingIndex = refinements.indexOf('4 & Up');

      const CONSOLE_TABLE_CATEGORY = 'Console Tables';
      const APPENDED_CONSOLE_TABLE_CATEGORY = 'Console Tables & Sofa Tables';
      const SOFA_BEDS = 'Sofa Beds';
      const APPENDED_SOFA_BEDS = 'Sofa Beds & Sleeper Sofas';

      if (titleCategories[0] === SOFA_BEDS) {
        titleCategories[0] = APPENDED_SOFA_BEDS;
      }
      if (bestRatedRatingIndex !== -1) {
        let processedRefinements = refinements;
        processedRefinements[bestRatedRatingIndex] = 'Best Rated';
        breadCrumbLabel = `${[...processedRefinements.slice().reverse(), ...titleCategories].join(' - ')} -`;
      } else if (highlyRatedRatingIndex !== -1) {
        let processedRefinements = refinements;
        processedRefinements[highlyRatedRatingIndex] = 'Highly Rated';
        breadCrumbLabel = `${[...processedRefinements.slice().reverse(), ...titleCategories].join(' - ')} -`;
      } else if (pickUpTodayIndex !== -1 && furnitureIndex === -1 && categories.length > 0) {
        let processedRefinements = refinements;
        processedRefinements[pickUpTodayIndex] = 'In Stock Near Me';
        breadCrumbLabel = `${[...processedRefinements.slice().reverse(), ...titleCategories].join(' - ')} -`;
      } else {
        if (titleCategories[0] === CONSOLE_TABLE_CATEGORY && refinements?.length === 0) {
          titleCategories[0] = APPENDED_CONSOLE_TABLE_CATEGORY;
        }
        breadCrumbLabel = `${[...refinements.slice().reverse(), ...titleCategories].join(' - ')} -`;
      }
    }
    return `${breadCrumbLabel} The Home Depot`;
  case 's':
    return `Search Results for ${searchVal} at The Home Depot`;
  default:
    return 'The Home Depot';
  }
};

export const getnValue = (canonical) => {
  if (!canonical) return null;
  const result = /N-(\w+)/.exec(canonical);
  if (result && result.length) {
    return result[1];
  }
  return null;
};

export const parseCrumbs = (crumbs = [], keyword, returnAll = false) => {
  if (keyword) {
    const textSearchCrumb = {
      url: '',
      label: 'Text Search'
    };

    let label;
    try {
      label = decodeURI(keyword);
    } catch (error) {
      label = keyword;
    }
    const keywordCrumb = {
      url: `/s/${keyword}?NCNI-5`,
      label
    };
    if (!crumbs.length) {
      crumbs.unshift(textSearchCrumb, keywordCrumb);
    } else {
      const textSearchCrumbIndex = crumbs.findIndex((crumb) => {
        return crumb.label === textSearchCrumb.label;
      });
      if (textSearchCrumbIndex === -1) {
        crumbs.unshift(textSearchCrumb, keywordCrumb);
      }
    }
  }
  if (crumbs.length > 0) {
    const hasCategory = crumbs.filter((crumb) => (crumb.dimensionName || '').toLowerCase() === 'category').length > 1;
    const breadcrumbs = hasCategory && !returnAll
      ? crumbs.filter((crumb) => (crumb.dimensionName || '').toLowerCase() === 'category')
      : crumbs.filter((crumb) => !crumb.deselectUrl);
    return breadcrumbs.map((crumb) => normalizeBreadcrumb(crumb));
  }
  return [];
};

export const removeSpecialCharacters = (string) => {
  return string.replace(/[&/\\#,+()$~%.'":*?<>{}]/g, '');
};

export const addSpaceForBreakTag = (string = '') => {
  return string?.replace(/<\s?br\s?\/?>/g, ' ');
};

// https://ourcodeworld.com/articles/read/376/how-to-strip-html-from-a-string-extract-only-text-content-in-javascript
export const stripHtml = (string = '') => {
  return string?.replace(/<[^>]+>/g, '');
};

export const getAllowedNoFollow = (pathWithQueryParams, canonicalUrl) => {
  const allLowercaseQuery = pathWithQueryParams.toLowerCase();
  const allowedUpperbound = /upperbound=(?:100|(?:2[05]|[3-6]0))0[&/]?$/;
  const upperbound = allLowercaseQuery.split('&').filter(((element) => element.match(allowedUpperbound)));
  if (
    allLowercaseQuery.indexOf('/b/furniture-living-room-furniture-sofas-couches') > -1
    && (allLowercaseQuery.indexOf('lowerbound=0') > -1 && upperbound.length)) {
    return false;
  }

  const noFollow = canonicalUrl?.indexOf('NCNI-5') > -1
    || pathWithQueryParams.indexOf('NCNI-5') > -1
    || pathWithQueryParams.indexOf('Ns=P_Topseller_Sort%7C1') > -1
    || pathWithQueryParams.indexOf('Ns=P_Smartsort%7C1') > -1
    || pathWithQueryParams.indexOf('Ns=P_REP_PRC_MODE%7C0') > -1
    || pathWithQueryParams.indexOf('Ns=P_REP_PRC_MODE%7C1') > -1
    || pathWithQueryParams.indexOf('Ns=P_Top_Rated_Sort%7C1') > -1
    || pathWithQueryParams.indexOf('sortorder=') > -1
    || pathWithQueryParams.indexOf('sortby=') > -1
    || pathWithQueryParams.indexOf('Ntk-') > -1
    || pathWithQueryParams.indexOf('/b/Featured-Products') > -1
    || pathWithQueryParams.indexOf('Ntt-') > -1
    || allLowercaseQuery.indexOf('lowerbound=') > -1
    || allLowercaseQuery.indexOf('upperbound=') > -1;

  return noFollow;
};