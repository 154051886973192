import React from 'react';
import PropTypes from 'prop-types';
import {
  useHelmet, TitleTag, MetaTag, ScriptTag, LinkTag
} from '@thd-nucleus/thd-helmet';
import {
  arrayOf as arrayType,
  customType,
  number as numberType,
  params,
  shape as shapeType,
  string as stringType,
  json,
  useDataModel,
  extend
} from '@thd-nucleus/data-sources';
import {
  EmtGeneratedContent
} from '@thd-olt-component-react/emt-generated-content';
import { useStoreId } from '@thd-nucleus/experience-context';
import { withErrorBoundary } from '@thd-olt-component-react/error-boundary';

import {
  getTitleTag,
  getnValue,
  removeSpecialCharacters,
  parseCrumbs,
  processBreadcrumbs,
  getDescription,
  getAllowedNoFollow,
} from './metadata-helpers';
import { getBreadcrumbStructuredData } from './structured-data/getBreadcrumbStructuredData';
import {
  getBrowseSearchStructuredData,
  getBrowseSearchFAQStructuredData,
  getEmtStructuredData
} from './structured-data/getBrowseSearchStructuredData';
import { OPEN_GRAPH_FACEBOOK_ADMIN } from '../constants';

const getSchemaValues = (data) => {
  if (!data) return {};

  try {
    const schema = JSON.parse(data);

    return { schema };
  } catch (error) {
    return {};
  }
};

const getMetadataValues = (emtResponse) => {
  const emtContent = emtResponse?.emtContent?.content?.metadata || {};
  const { schema = '' } = getSchemaValues(emtContent?.schema);

  return {
    emtTitle: emtContent?.title,
    emtMetaDescription: emtContent?.metaDescription,
    emtOgTitle: emtContent?.ogTitle,
    emtOgDescription: emtContent?.ogDescription,
    emtOgType: emtContent?.ogType,
    emtOgImage: emtContent?.ogImage,
    emtSchema: schema
  };
};

const browseHandler = ({ data, props = {} }) => {
  if (!data?.searchModel) return null;

  const tags = [];
  const { pathWithQueryParams, isTableView } = props;
  const { emtGeneratedContent, emtData } = data;
  const {
    searchReport,
    metadata,
    taxonomy,
    templates,
    products
  } = data?.searchModel;

  let parsedTemplates;

  try {
    parsedTemplates = JSON.parse(templates?.[0]);
  } catch {
    parsedTemplates = templates?.[0];
  }

  const {
    emtTitle,
    emtMetaDescription,
    emtOgTitle,
    emtOgDescription,
    emtOgType,
    emtOgImage,
    emtSchema
  } = getMetadataValues(emtData);
  const { keyword: searchReportKeyword } = searchReport || {};
  const nValue = getnValue(metadata?.canonicalUrl);
  const canonical = `https://www.homedepot.com${metadata?.canonicalUrl}`;
  const pageType = pathWithQueryParams.indexOf('/b/') > -1 ? 'b' : 's';
  const processedBreadcrumbs = parseCrumbs([...(taxonomy?.breadCrumbs || [])], searchReportKeyword);
  const isContentPageOnly = parsedTemplates?.options?.content_type === 'categorypage';
  const [categories, refinements] = processBreadcrumbs(taxonomy?.breadCrumbs);
  const isHybrid = metadata?.hasPLPBanner;
  const isBrandPage = taxonomy?.breadCrumbs?.some((crumb) => crumb.dimensionName === 'Brand');
  let description = getDescription(pageType, categories, refinements, searchReportKeyword);
  let title = getTitleTag(pageType, categories, refinements, searchReportKeyword, pathWithQueryParams);
  let type = 'PLP';
  if (isTableView) {
    type = 'PLP Table View';
  }
  if (isContentPageOnly) {
    if (!(categories[0] === 'Appliances' && pageType === 'b')) {
      description = parsedTemplates?.options?.metaData?.description;
    }
    title = parsedTemplates?.options?.metaData?.title;
    type = isBrandPage ? 'Category brand' : 'Category';
  } else if (isHybrid) {
    type = isBrandPage ? 'cat PLP brand' : 'cat PLP';
    if (isTableView && type === 'cat PLP') {
      type = 'cat PLP Table View';
    }
  } else if (isBrandPage) {
    type = 'PLP brand';
  }

  const isNoFollow = getAllowedNoFollow(pathWithQueryParams, metadata?.canonicalUrl);

  const finalTitle = emtTitle || title;
  const finalMetaDescription = emtMetaDescription || description;
  const finalOgTitle = emtOgTitle || title;
  const finalOgDescription = emtOgDescription || description;
  const finalOgType = emtOgType || type;

  tags.push(new TitleTag(finalTitle));
  tags.push(new LinkTag({ rel: 'canonical', href: canonical, id: 'canonical' }));
  tags.push(new MetaTag({ name: 'description', content: finalMetaDescription, id: 'description' }));
  tags.push(new MetaTag({ property: 'og:title', content: finalOgTitle, id: 'ogTitle' }));
  tags.push(new MetaTag({ property: 'og:url', content: canonical, id: 'ogUrl' }));
  tags.push(new MetaTag({ property: 'og:type', content: finalOgType, id: 'ogType' }));
  if (emtOgImage) tags.push(new MetaTag({ property: 'og:image', content: emtOgImage, id: 'ogImage' }));
  tags.push(new MetaTag({ property: 'og:description', content: finalOgDescription, id: 'ogDescription' }));
  tags.push(new MetaTag({ property: 'og:site_name', content: 'The Home Depot', id: 'ogSiteName' }));
  tags.push(new MetaTag({ property: 'fb:admins', content: OPEN_GRAPH_FACEBOOK_ADMIN, id: 'fbAdmins' }));
  if (isNoFollow) {
    tags.push(new MetaTag({ name: 'ROBOTS', content: 'NOINDEX, NOFOLLOW', id: 'robots' }));
  } else {
    tags.push(new MetaTag({ name: 'robots', content: 'max-image-preview:large', id: 'robots' }));
  }

  if (processedBreadcrumbs && !!processedBreadcrumbs.length) {
    const formattedLabel = removeSpecialCharacters(processedBreadcrumbs[0].label).replace(/\s+/g, '-');

    tags.push(new LinkTag({
      rel: 'alternate',
      media: 'only screen and (max-width: 640px)',
      href: `android-app://com.thehomedepot/homedepot/categoryid/${nValue}/${formattedLabel}`,
      id: 'alternate'
    }));
  }

  if (isContentPageOnly) {
    const breadcrumbStructuredData = getBreadcrumbStructuredData({
      canonical,
      breadcrumbs: processedBreadcrumbs,
      title
    });
    tags.push(new ScriptTag({ content: breadcrumbStructuredData, id: 'breadcrumbStructuredData' }));
  } else if (emtGeneratedContent?.questionAndAnswer) {
    // If there is no schema return the default structured data
    if (!emtSchema) {
      const content = getBrowseSearchFAQStructuredData({
        canonical,
        title,
        breadcrumbs: processedBreadcrumbs,
        faq: emtGeneratedContent?.questionAndAnswer
      });

      tags.push(new ScriptTag({ content, id: 'structureDataFAQ' }));
    } else {
      // If there is a schema response from the emtContent API do our custom function
      const content = getEmtStructuredData({
        faq: emtGeneratedContent?.questionAndAnswer,
        schema: emtSchema,
        breadcrumbs: processedBreadcrumbs,
      });

      tags.push(new ScriptTag({ content, id: 'emtGeneratedContentFAQ' }));
    }
  } else {
    const content = emtSchema || getBrowseSearchStructuredData({
      canonical,
      skus: products,
      title,
      breadcrumbs: processedBreadcrumbs
    });

    tags.push(new ScriptTag({ content, id: 'browseSearchStructuredData' }));
  }

  return tags;
};

const BrowseSearchMetadata = (props) => {
  const {
    keyword,
    navParam,
    pathWithQueryParams,
    variables: propVariables,
    seoGeneratedContentVariables,
    pageWithFAQ,
    isTableView
  } = props;
  const storeId = useStoreId();

  let data;
  let error;
  let loading;

  const variables = propVariables || {
    keyword,
    navParam,
    storeId,
  };
  // eslint-disable-next-line
  const isControlledData = typeof props.data !== 'undefined' || typeof props.loading !== 'undefined';
  const skipSearch = isControlledData || (!keyword && !navParam && !propVariables);
  const searchOptions = {
    variables,
    skip: skipSearch,
  };
  ({
    data,
    loading,
    error,
  } = useDataModel('searchModel', searchOptions));
  let nval;
  const seoNavParam = seoGeneratedContentVariables?.navParam;
  if (seoNavParam) {
    nval = (seoNavParam.indexOf('N-') < 0) ? `N-${seoNavParam}` : seoNavParam;
  }
  const response = useDataModel('emtGeneratedContent', {
    variables: {
      nvalue: nval
    },
    skip: !nval
  });

  const emtContentOptions = {
    variables: {
      contentId: nval,
      pageName: 'plp'
    },
    skip: !nval
  };
  const { data: emtData, loading: emtLoading, error: emtError } = useDataModel('emtContent', emtContentOptions);

  if (isControlledData) {
    ({
      data,
      loading,
      error
    } = props);
  }

  const helmetData = {
    ...data,
    ...response.data,
    emtData
  };

  useHelmet('browseAndSearch',
    { data: helmetData, props },
    browseHandler,
    [keyword, navParam, data?.searchModel, response?.data?.emtGeneratedContent]);
  return null;
};

BrowseSearchMetadata.displayName = 'BrowseSearchMetadata';

BrowseSearchMetadata.dataModel = extend({
  searchModel: params({
    keyword: stringType(),
    storefilter: customType('StoreFilter').enum(['ALL', 'IN_STORE', 'ONLINE'], 'ALL'),
    navParam: stringType()
  }).shape({
    metadata: shapeType({
      canonicalUrl: stringType()
    }),
    products: params().arrayOf({
      itemId: stringType(),
      dataSources: stringType(),
      identifiers: shapeType({
        brandName: stringType(),
        itemId: stringType(),
        productLabel: stringType()
      }),
      media: shapeType({
        images: arrayType({
          url: stringType()
        })
      }),
      pricing: params({ storeId: stringType() }).shape({
        value: numberType({ float: true })
      }),
      reviews: shapeType({
        ratingsReviews: shapeType({
          averageRating: stringType(),
          totalReviews: stringType()
        })
      })
    }),
    searchReport: shapeType({
      keyword: stringType()
    }),
    taxonomy: shapeType({
      brandLinkUrl: stringType(),
      breadCrumbs: arrayType(shapeType({
        browseUrl: stringType(),
        creativeIconUrl: stringType(),
        deselectUrl: stringType(),
        dimensionId: stringType(),
        dimensionName: stringType(),
        label: stringType(),
        refinementKey: stringType(),
        url: stringType()
      }))
    }),
    templates: arrayType(stringType())
  }),
  emtContent: params({
    contentId: stringType().isRequired(),
    pageName: stringType().isRequired()
  }).shape({
    content: json()
  })
}, EmtGeneratedContent);

const VariablesObject = PropTypes.shape({
  navParam: PropTypes.string
});

BrowseSearchMetadata.propTypes = {
  keyword: PropTypes.string,
  seoGeneratedContentVariables: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.instanceOf(VariablesObject)
  ]),
  navParam: PropTypes.string,
  pathWithQueryParams: PropTypes.string.isRequired,
  data: PropTypes.shape({}),
  loading: PropTypes.bool,
  pageWithFAQ: PropTypes.bool,
  variables: PropTypes.shape({}),
  isTableView: PropTypes.bool
};

BrowseSearchMetadata.defaultProps = {
  keyword: null,
  navParam: null,
  data: undefined,
  pageWithFAQ: undefined,
  loading: undefined,
  seoGeneratedContentVariables: null,
  variables: null,
  isTableView: false
};

const ErrorBoundBrowseAndSearchMetadata = withErrorBoundary(BrowseSearchMetadata);

export { ErrorBoundBrowseAndSearchMetadata as BrowseSearchMetadata };
